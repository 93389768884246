const ELEMENTARY_SCHOOL = [
  {
    value: 1,
    title: '1º ano'
  },
  {
    value: 2,
    title: '2º ano'
  },
  {
    value: 3,
    title: '3º ano'
  },
  {
    value: 4,
    title: '4º ano'
  },
  {
    value: 5,
    title: '5º ano'
  },
  {
    value: 6,
    title: '6º ano'
  },
  {
    value: 7,
    title: '7º ano'
  },
  {
    value: 8,
    title: '8º ano'
  },
  {
    value: 9,
    title: '9º ano'
  }
];

const HIGH_SCHOOL = [
  {
    value: 10,
    title: '1º ano'
  },
  {
    value: 11,
    title: '2º ano'
  },
  {
    value: 12,
    title: '3º ano'
  }
];

const SCHOOL_TYPE = [
  {
    value: 1,
    title: 'Ensino Fundamental'
  },
  {
    value: 2,
    title: 'Ensino Médio'
  }
];

export {
  ELEMENTARY_SCHOOL,
  HIGH_SCHOOL,
  SCHOOL_TYPE
};
