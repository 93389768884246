





































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import ExSelect from '@/components/Input/Select/Select.vue';
import IPlus from '@/assets/icons/Plus.vue';
import ListConfig from './components/ListConfig/ListConfig.vue';

import WarService from '@/services/War/WarService';

import { ELEMENTARY_SCHOOL, HIGH_SCHOOL, SCHOOL_TYPE } from './constants/constants';

import {
  Institution,
  Campus,
  Course,
  Bench
} from '@/pages/War/interface/config';

import { Select, Options } from './interface';

const MAX_OPTION_SELECT = 2;

@Component({
  components: {
    ExSelect,
    IPlus,
    ListConfig
  }
})
export default class SelectConfig extends Vue {
  private isLoadingUniversity = true;
  private isLoadingCampus = true;
  private isLoadingCourse = true;
  private isLoadingBench = true;

  private listUniversities: Institution[] | null = null;
  private listCampus: Campus[] | null = null;
  private listCourses: Course[] | null = null;
  private listBench: Bench[] | null = null;

  private university: Institution | null = null;
  private campus: Campus | null = null;
  private course: Course | null = null;
  private bench: Bench | null = null;

  private universitySelect: number | null = null;
  private campusSelect: number | null = null;
  private courseSelect: number | null = null;
  private benchSelect: number | null = null;

  private listConfig: Options[] = [];
  private schoolTypeID = 0;
  private schoolTypeList = SCHOOL_TYPE;
  private elementarySchoolList = ELEMENTARY_SCHOOL;
  private highSchoolList = HIGH_SCHOOL;
  private schoolID = 0;

  private WarService = new WarService();

  created() {
    // this.getUniversity();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get listUniversitiesSelect(): Select[] | null {
    if (!this.listUniversities || !this.listUniversities?.length) return null;

    const universitiesSelect = this.listUniversities?.map((university: Institution) => ({
      title: university.name,
      value: university.id
    }));

    return universitiesSelect || null;
  }

  get listCampusSelect(): Select[] | null {
    if (!this.listCampus || !this.listCampus?.length) return null;

    const campusSelect = this.listCampus?.map((campus: Campus) => ({
      title: campus.name,
      value: campus.ID
    }));

    return campusSelect || null;
  }

  get listCoursesSelect(): Select[] | null {
    if (!this.listCourses || !this.listCourses?.length) return null;

    const coursesSelect = this.listCourses?.map((course: Course) => ({
      title: course.courseName,
      value: course.courseId
    }));

    return coursesSelect || null;
  }

  get listBenchSelect(): Select[] | null {
    if (!this.listBench || !this.listBench?.length) return null;

    const benchSelect = this.listBench?.map((bench: Bench) => ({
      title: bench.name,
      value: bench.ID
    }));

    return benchSelect || null;
  }

  get validateConfig() {
    return (
      this.university
      && this.campus
      && this.course
      // && this.bench
    );
  }

  get showAddOption() {
    return this.listConfig.length <= MAX_OPTION_SELECT;
  }

  async getUniversity() {
    try {
      this.isLoadingUniversity = true;

      const response = await this.WarService.getInstitutions();

      if (response) {
        this.listUniversities = response;
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as instituições.',
        status: 'error'
      });
    } finally {
      this.isLoadingUniversity = false;
    }
  }

  @Watch('university')
  async getCampus() {
    if (!this.university?.id) return;

    try {
      this.isLoadingCampus = true;

      const response = await this.WarService.getCampus({ institutionId: this.university?.id });

      if (response) {
        this.listCampus = response;
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os campus.',
        status: 'error'
      });
    } finally {
      this.isLoadingCampus = false;
    }
  }

  @Watch('campus')
  async getCourses() {
    if (!this.university?.id || !this.campus?.ID) return;

    try {
      this.isLoadingCourse = true;

      const response = await this.WarService.getCourses({
        institutionId: this.university?.id,
        campusId: this.campus?.ID
      });
      console.log('🚀 ~ Config ~ getCourses ~ response:', response);

      if (response) {
        this.listCourses = response;
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os cursos.',
        status: 'error'
      });
    } finally {
      this.isLoadingCourse = false;
    }
  }

  // @Watch('campus')
  // async getBench() {
  //   if (!this.campus?.ID) return;

  //   try {
  //     this.isLoadingBench = true;

  //     const response = await this.WarService.getBench(this.campus?.ID);

  //     if (response) {
  //       this.listBench = response;
  //     }

  //   } catch (error) {
  //     this.$store.dispatch('Toast/setToast', {
  //       text: 'Erro ao carregar as bancas.',
  //       status: 'error',
  //     });
  //   } finally {
  //     this.isLoadingBench = false;
  //   }
  // }

  @Watch('listConfig', {
    deep: true
  })
  async emitListConfig() {
    this.$emit('listConfig', this.listConfig);
  }

  emitSchool() {
    this.$emit('schoolID', this.schoolID);
  }

  selectedSchoolType(id: number) {
    this.schoolTypeID = id;
    this.schoolID = 0;
  }

  selectedSchool(id: number) {
    this.schoolID = id;
    this.emitSchool();
  }

  selectedUniversity(id: number) {
    if (!this.listUniversities || !this.listUniversities?.length) return;

    const findUniversity = this.listUniversities?.find((university: Institution) => university.id === id);

    if (findUniversity) {
      this.university = findUniversity;
      this.universitySelect = id;
    }
  }

  selectedCampus(id: number) {
    if (!this.listCampus || !this.listCampus?.length) return;

    const findCampus = this.listCampus?.find((campus: Campus) => campus.ID === id);

    if (findCampus) {
      this.campus = findCampus;
      this.campusSelect = id;
    }
  }

  selectedCourse(id: number) {
    if (!this.listCourses || !this.listCourses?.length) return;

    const findCourse = this.listCourses?.find((course: Course) => course.courseId === id);

    if (findCourse) {
      this.course = findCourse;
      this.courseSelect = id;
    }
  }

  selectedBench(id: number) {
    if (!this.listBench || !this.listBench?.length) return;

    const findBench = this.listBench?.find((bench: Bench) => bench.ID === id);

    if (findBench) {
      this.bench = findBench;
      this.benchSelect = id;
    }
  }

  handleAddOptionConfig() {
    if (!this.university || !this.campus || !this.course) return;

    this.listConfig.push({
      university: {
        id: this.university.id,
        name: this.university.name
      },
      campus: {
        id: this.campus.ID,
        name: this.campus.name
      },
      course: {
        id: this.course.courseId,
        name: this.course.courseName
      }
      // bench: {
      //   id: this.bench.ID,
      //   name: this.bench.name,
      // },
    });

    this.resetSelect();
  }

  removeOptionConfig(index: number) {
    this.listConfig.splice(index, 1);
  }

  resetSelect() {
    this.listCampus = null;
    this.listCourses = null;
    this.listBench = null;

    this.university = null;
    this.campus = null;
    this.course = null;
    this.bench = null;

    this.universitySelect = null;
    this.campusSelect = null;
    this.courseSelect = null;
    this.benchSelect = null;
  }
}
