




import { Component, Vue, Prop } from 'vue-property-decorator';

import { CharacterVariations, ConfigWar } from '@/globalInterfaces/War';

@Component({})
export default class Character extends Vue {
  @Prop() private slug!: string;

  get configWar(): ConfigWar | null {
    return this.$store.getters['War/configWar'];
  }

  get character(): CharacterVariations | null {
    const characterName = this.configWar?.character.name;

    const slug = `${characterName}-${this.slug}`;

    return (
      this.configWar?.character?.variations?.find((character) => character.slug === slug) || null
    );
  }
}
