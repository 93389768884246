import AppClient from '../AppClient';

import {
  GetCampus,
  GetCourses,
  CardResponse,
  ResponseCountries,
  CreateConfig,
  UpdateConfig,
  ResponseCardUser,
  ResponseCountryUser,
  SelectedTopicResponse,
  RecommendationTopicResponse
} from './WarInterface';

import { List } from '@/globalInterfaces/List';
import { Character } from '@/globalInterfaces/War';
import {
  Institution,
  Campus,
  Course,
  Bench
} from '@/pages/War/interface/config';

export default class VideoService {
  private basePathSimulation = process.env.VUE_APP_SIMULATION_API;
  private basePathBook = process.env.VUE_APP_BOOK_API;

  private ClientSimulation = new AppClient({ url: this.basePathSimulation });
  private ClientBook = new AppClient({ url: this.basePathBook });

  async getConfig() {
    const URI = '/war/config';

    const response = await this.ClientSimulation.get(URI);

    return response?.data;
  }

  async createConfig(data: CreateConfig) {
    const URI = '/war/config';

    const response = await this.ClientSimulation.post(URI, data);

    return response?.data;
  }

  async updateConfig(data: UpdateConfig) {
    const URI = '/war/config';

    const response = await this.ClientSimulation.put(URI, data);

    return response?.data;
  }

  async getListLeveling(): Promise<List> {
    const URI = '/lists/war';

    const response = await this.ClientSimulation.get(URI);

    return response?.data;
  }

  async getCardUser() {
    const URI = '/cards/users';

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async getListCharacters(): Promise<Character[]> {
    const URI = '/characters';

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async getInstitutions(): Promise<Institution[]> {
    const URI = '/higher/institution';
    const response = await this.ClientBook.get(URI);

    return response?.data;
  }
  // WarRoom
  async countryAtack(countryId: number) {
    const URI = `war/country/${countryId}/attack`;

    const response = await this.ClientBook.post(URI);

    return response?.data;
  }

  async countryCharacters(countryId: number) {
    const URI = `characters/${countryId}`;

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async getCampus(data: GetCampus): Promise<Campus[]> {
    const URI = '/higher/campus';

    const response = await this.ClientBook.get(URI, {
      ...data
    });

    return response?.data;
  }

  async getCourses(data: GetCourses): Promise<Course[]> {
    const URI = '/higher/courses';

    const response = await this.ClientBook.get(URI, {
      ...data
    });

    return response?.data;
  }

  async getBench(campusId: number): Promise<Bench[]> {
    const URI = `/higher/campus/${campusId}/bench`;
    const response = await this.ClientBook.get(URI);

    return response?.data;
  }
  async countryCards(countryId: number) {
    const URI = `cards/${countryId}`;

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async countries(): Promise<ResponseCountries[]> {
    const URI = 'countries';

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async countriesUsers(): Promise<ResponseCountryUser[]> {
    const URI = 'countries/users';

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async countriesCategories() {
    const URI = 'countries/categories';

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async cardUser(): Promise<ResponseCardUser> {
    const URI = 'cards/users';

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  async setCardUser(data: { cardId: number; userId: number }) {
    const URI = `cards/${data.cardId}/users/${data.userId}`;

    const response = await this.ClientBook.put(URI);

    return response?.data;
  }

  async getCountryCard(cardId: number): Promise<CardResponse> {
    const URI = `cards/${cardId}`;

    const response = await this.ClientBook.get(URI);

    return response?.data;
  }

  // Training Center
  async getRecommendations(): Promise<RecommendationTopicResponse[]> {
    const URI = 'war/topics-recommendations';
    const response = await this.ClientSimulation.get(URI);
    return response?.data;
  }

  async getSelectedTopics(warStepID: number): Promise<SelectedTopicResponse[]> {
    const URI = `war/selected-topics?warStepID=${warStepID}`;
    const response = await this.ClientSimulation.get(URI);
    return response?.data;
  }

  async removeSelectedTopic(warStepID: number) {
    const URI = `war/remove-selected-topic?topicID=${warStepID}`;
    const response = await this.ClientSimulation.delete(URI);
    return response?.data;
  }

  async SelectedTopic(topicID: number) {
    const URI = 'war/select-topic';
    const response = await this.ClientSimulation.post(URI, { topicID });
    return response?.data;
  }

  async GenerateList(topicID: number) {
    const URI = 'war/generate-list';
    const response = await this.ClientSimulation.post(URI, { topicID });
    return response?.data;
  }
}
