



































import { Component, Vue } from 'vue-property-decorator';

import Menu from '../../components/Menu/Menu.vue';
import Background from '../../components/Background/Background.vue';
import Character from '../../components/Character/Character.vue';
import Configuration from './components/Configuration/Configuration.vue';

import ConfigLoading from './ConfigLoading.vue';

import WarService from '@/services/War/WarService';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { ConfigWar } from '@/globalInterfaces/War';

import { menu } from '../../constants';

@Component({
  components: {
    Menu,
    Background,
    ConfigLoading,
    Configuration,
    Character
  }
})
export default class Config extends Vue {
  private isLoading = true;
  private MENU = menu;

  private WarService = new WarService();

  created() {
    this.getConfig();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get listMenu() {
    const newMenu = this.MENU;

    return newMenu;
  }

  get configWar(): ConfigWar | null {
    return this.$store.getters['War/configWar'];
  }

  async getConfig() {
    if (this.configWar) {
      this.isLoading = false;

      return;
    }

    try {
      this.isLoading = true;

      const config = await this.WarService.getConfig();

      this.$store.commit('War/setConfig', config);
    } catch (error) {
      if (error?.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND) return;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as configurações.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }
}
