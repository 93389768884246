
































































import { Component, Vue } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';
import Select from '@/components/Input/Select/Select.vue';

import { NAME_ROUTES_CONQUISTAE } from '@/router/routes/War';

import AcceptSwitch from '../AcceptSwitch/AcceptSwitch.vue';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch.vue';
import SelectConfig from '../SelectConfig/SelectConfig.vue';

import WarService from '@/services/War/WarService';
import { OptionsConfig } from '@/services/War/WarInterface';

import { ConfigWar } from '@/globalInterfaces/War';

import { Options } from '../SelectConfig/interface';

@Component({
  components: {
    AcceptSwitch,
    Select,
    LanguageSwitch,
    Loading,
    SelectConfig
  }
})
export default class Configuration extends Vue {
  private acceptEnem = true;
  private acceptRedaction = true;
  private language = true;
  private schoolID = 0;
  private isLoading = false;

  private optionsConfig: OptionsConfig[] = [];

  private warService = new WarService();

  get configWar(): ConfigWar | null {
    return this.$store.getters['War/configWar'];
  }

  get isDisabledButton() {
    return !this.schoolID
      || this.language === undefined
      || this.isLoading;
  }

  handleSetAcceptEnem(value: boolean) {
    this.acceptEnem = value;
  }

  handleSetAcceptRedaction(value: boolean) {
    this.acceptRedaction = value;
  }

  handleSetLanguage(value: boolean) {
    this.language = value;
  }

  async handleNextStep() {
    try {
      this.isLoading = true;

      await this.warService.updateConfig({
        degreeID: this.schoolID,
        isEnglish: this.language
      });
      this.redirectToLeveling();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar as configurações.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  redirectToLeveling() {
    this.$router.push({ name: NAME_ROUTES_CONQUISTAE.LEVELING });
  }

  setSchoolID(schoolID: number) {
    if (!this.configWar || !this.configWar?.ID) return;
    this.schoolID = schoolID;
  }

  setOptionConfig(listConfig: Options[]) {
    if (!this.configWar || !this.configWar?.ID) return;

    this.optionsConfig = listConfig.map((config: Options, index: number) => ({
      warConfigID: this.configWar?.ID || 0,
      higherInstitutionsID: config.university.id,
      higherCampusID: config.campus.id,
      higherCoursesID: config.course.id,
      boardID: 1,
      // boardID: config.bench.id,
      order: index + 1
    }));
  }
}
