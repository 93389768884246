










































import { Component, Prop, Vue } from 'vue-property-decorator';

import IMinus from '@/assets/icons/Minus.vue';

import { Options } from '../../interface';

@Component({
  components: {
    IMinus
  }
})
export default class ListConfig extends Vue {
  @Prop({ required: true }) options!: Options[];

  handleAddOptionConfig(index: number) {
    this.$emit('remove', index);
  }
}
