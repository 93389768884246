import { Card as ICard } from '../interface/card';

export const menu = [
  {
    title: '1ª Missão |  Definir Objetivos',
    active: true
  },
  {
    title: '2ª Missão |  Avaliação de Admissão',
    active: false
  }
];

export const DEFAULT_CARD: ICard = {
  ID: -1,
  description: '',
  title: '',
  image: '/assets/imgs/default_card.png',
  color: '#4B4B4B',
  airForce: 100000,
  navy: 200000,
  soldiers: 500000,
  vehicles: 350000,
  resources: 200000,
  created_date: '2023-10-01',
  updated_date: '2023-10-01',
  status: 1,
  country: {
    ID: 1,
    categoryID: -1,
    categoryName: '',
    title: '',
    image: '',
    status: 1
  }
};
